import React from "react";
import { graphql } from "gatsby";
import HeroProgrammesWithBox from "../components/reusable/HeroProgrammesWithBox";
import CourseOverview from "../components/reusable/CourseOverview";
import Video from "../components/reusable/Video";
import Modules from "../components/reusable/Modules";
import KeyFeatures from "../components/reusable/KeyFeatures";
import CourseTutorSlider from "../components/reusable/CourseTutorSlider";
import IsThisCourseForMe from "../components/reusable/IsThisCourseForMe";
import TakeTheNextStep from "../components/reusable/TakeTheNextStep";
import MethodsOfStudy from "../components/reusable/MethodsOfStudy";
import Testimonials from "../components/page-components/Homepage/Testimonials";
// import TestimonialsSlider from '../components/reusable/TestimonialsSlider'
import Seo from "../components/global/seo";

export default function FakeProgramme({ data }) {
  const pageData = data.wpPage.interactive_programme;
  //console.log('test', data.wpPage.seo.slug)
  return (
    <div>
      <Seo
        title={data.wpPage.seo.title}
        description={data.wpPage.seo.metaDesc}
      />
      {pageData?.detailPageInteractive?.heroImage?.localFile &&
      data.wpPage.title &&
      pageData.detailPageInteractive.boxText ? (
        <HeroProgrammesWithBox
          image={pageData?.detailPageInteractive?.heroImage?.localFile}
          title={data.wpPage.title}
          boxText={pageData.detailPageInteractive.boxText}
        />
      ) : (
        ""
      )}
      {data.wpPage.title &&
      pageData.detailPageInteractive.courseOverview &&
      pageData.detailPageInteractive.courseOverviewHeading &&
      pageData.snipcartInfo &&
      data.wpPage.slug ? (
        <CourseOverview
          title={data.wpPage.title}
          sidebox={"register_interest"}
          courseOverview={pageData.detailPageInteractive.courseOverview}
          courseOverviewHeading={
            pageData.detailPageInteractive.courseOverviewHeading
          }
          isFakeProgramme={true}
          snipcart={pageData.snipcartInfo}
          slug={data.wpPage.slug}
        />
      ) : (
        ""
      )}

      {pageData.detailPageInteractive.video.length > 0 ? (
        <Video
          video={pageData.detailPageInteractive.video}
          addBottomColor={true}
          bottomColor={"black"}
        />
      ) : (
        ""
      )}

      {pageData.detailPageInteractive.moduleIntroText &&
      pageData.detailPageInteractive.interactiveProgrammeModules &&
      pageData.detailPageInteractive.moduleIntroHeading ? (
        <Modules
          title="Modules"
          introText={pageData.detailPageInteractive.moduleIntroText}
          modules={pageData.detailPageInteractive.interactiveProgrammeModules}
          moduleIntroHeading={pageData.detailPageInteractive.moduleIntroHeading}
          isFakeProgramme={true}
        />
      ) : (
        ""
      )}

      {pageData.detailPageInteractive.interactiveProgrammeUsps &&
      pageData.detailPageInteractive.uspsSectionTitle ? (
        <KeyFeatures
          features={pageData.detailPageInteractive.interactiveProgrammeUsps}
          uspsSectionTitle={pageData.detailPageInteractive.uspsSectionTitle}
        />
      ) : (
        ""
      )}

      {pageData.detailPageInteractive.interactiveProgrammeInstructors &&
      pageData.detailPageInteractive.instructorsHeading ? (
        <CourseTutorSlider
          tutors={
            pageData.detailPageInteractive.interactiveProgrammeInstructors
          }
          instructorsHeading={pageData.detailPageInteractive.instructorsHeading}
        />
      ) : (
        ""
      )}

      {pageData.detailPageInteractive.interactiveProgrammeIsThisCourseForMe &&
      pageData.detailPageInteractive
        .interactiveProgrammeIsThisCourseForMeHeading ? (
        <IsThisCourseForMe
          items={
            pageData.detailPageInteractive.interactiveProgrammeIsThisCourseForMe
          }
          interactiveProgrammeIsThisCourseForMeHeading={
            pageData.detailPageInteractive
              .interactiveProgrammeIsThisCourseForMeHeading
          }
        />
      ) : (
        ""
      )}
      {data.wpPage.title &&
      pageData.detailPageInteractive
        .interactiveProgrammeTakeNextStepIntroText &&
      pageData.detailPageInteractive.interactiveProgrammeTakeNextStepHeading &&
      pageData.snipcartInfo &&
      data.wpPage.slug ? (
        <TakeTheNextStep
          title={data.wpPage.title}
          takeTheNextStepIntroText={
            pageData.detailPageInteractive
              .interactiveProgrammeTakeNextStepIntroText
          }
          interactiveProgrammeTakeNextStepHeading={
            pageData.detailPageInteractive
              .interactiveProgrammeTakeNextStepHeading
          }
          isFakeProgramme={true}
          snipcart={pageData.snipcartInfo}
          slug={data.wpPage.slug}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      seo {
        title
        metaDesc
      }
      interactive_programme {
        snipcartInfo {
          price
          productName
          productDescription
          productImage {
            localFile {
              publicURL
            }
          }
          productId
        }
        detailPageInteractive {
          courseOverview
          courseOverviewHeading
          uspsSectionTitle
          instructorsHeading
          interactiveProgrammeIsThisCourseForMeHeading
          interactiveProgrammeTakeNextStepHeading
          video
          boxText
          heroImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          moduleIntroText
          moduleIntroHeading
          interactiveProgrammeModules {
            moduleTitle
            moduleDescription
          }
          interactiveProgrammeTakeNextStepIntroText
          interactiveProgrammeUsps {
            text
            title
            icon {
              localFile {
                publicURL
              }
            }
          }
          interactiveProgrammeIsThisCourseForMe {
            answer
            question
          }
          interactiveProgrammeInstructors {
            instructor {
              ... on WpInstructor {
                title
                instructor {
                  biography
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 1500
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
